import React, { useState } from "react";
import { Tab } from "../../tabs/tabs.tsx";
import "./nav.css";

const Nav = () => {

    const [navOpen, setNavOpen] = useState(false);

    function clickOnHome() {
        setTimeout(() => { window.scrollTo(0, 0); }, 200);
        setNavOpen(!navOpen);
        window.dataLayer.push({ 'event': 'home_view' });
    }

    function clickOnAbout() {
        setTimeout(() => { window.scrollTo(0, 0); }, 200);
        setNavOpen(!navOpen);
        window.dataLayer.push({ 'event': 'about_view' });
    }

    function clickOnPortfolio() {
        setTimeout(() => { window.scrollTo(0, 0); }, 200);
        setNavOpen(!navOpen);
        window.dataLayer.push({ 'event': 'portfolio_view' });

    }
    function clickOnServices() {
        setTimeout(() => { window.scrollTo(0, 0); }, 200);
        setNavOpen(!navOpen);
        window.dataLayer.push({ 'event': 'services_view' });

    }
    function clickOnBlog() {
        setTimeout(() => { window.scrollTo(0, 0); }, 200);
        setNavOpen(!navOpen);
        window.dataLayer.push({ 'event': 'blog_view' });
    }

    return (

        <div className="nav">
            <div className="nav-container">
                <div className="navbar">
                    <div className="navbarlogo">
                           <img  style={{maxWidth:"100px"}} src="/assets/img/logo/dark.png" alt="brand" />
                    </div>
                    <div className="menu-toggle" onClick={() => setNavOpen(!navOpen)}>
                        <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"} >
                            <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
                            <span className={navOpen ? "lineBottom spin" : "lineBottom"}></span>
                        </div>
                    </div>
                </div>
                <div className="nav-overlay"
                    style={{
                        top: navOpen ? "0" : "-100%",
                        transitionDelay: navOpen ? "0s" : "0s",
                    }}
                >
                    <ul>
                        <Tab to="/" onClick={clickOnHome}>
                            <span>Home</span>
                        </Tab>
                        <Tab to="/about"onClick={clickOnAbout}>
                            <span >About</span>
                        </Tab>
                        {/* <Tab to="/services" onClick={clickOnServices}>
                            <span >What I do</span>
                        </Tab> */}
                        <Tab to="/portfolio" onClick={clickOnPortfolio}>
                            <span>Portfolio</span>
                        </Tab>
                        <Tab to="/blog" onClick={clickOnBlog}>
                            <span>Blog</span>
                        </Tab>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Nav;